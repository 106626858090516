import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

import BadRagaz from '../../images/jpg/BadRagaz.jpg'

const styles = (theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.secondary.light,
    overflow: 'hidden',
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(15),
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  title: {
    textAlign: 'center',
    marginBottom: theme.spacing(14),
  },
  number: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  image: {
    height: 55,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    opacity: 0.7,
  },
  button: {
    marginTop: theme.spacing(8),
  },
  hotelImageContainer: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    alignItems: 'center',
    justifyContent: 'center'
  },
  hotelImage : {
    width: 'auto',
    height: '80%',
  }
});

function Gift(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Typography variant="h4" marked="center" className={classes.title} component="h2">
          Hier ist dein Geschenk
        </Typography>
        <div>
          Wenn du schon dachtest die Fotos wären dein endgültiges Geschenk, dann hast du falsch gedacht ;)! Ich bin mir bewusst, dass wir aufgrund von Corona recht flexibel sein müssen und
          du je nachdem wie deine zukünftigen Pläne mit einem Studium aussehen auch relativ eingeschränkt bist mit deiner Zeit. Ich möchte dir deshalb gerne eine Hotelübernachtung
          im 5 Sterne Hotel 'Grand Resort Bad Ragaz' schenken. Bad Ragaz ist noch relativ leicht erreichbar und wunderbar für ein kurzes gemeinsames Wochenende zu zweit. Im Sommer können wir in der
          Umgebung beispielsweise Wandern gehen oder im Hotel Minigolf spielen. Im Winter besteht auch die Möglichkeit in der Nähe Skifahren zu gehen. Im Anschluss haben wir
          uns auf jeden Fall den Besuch im Hotel Spa verdient.
        </div>

        <div className={classes.hotelImageContainer}>
          <img src={BadRagaz} alt='Bad Ragaz' className={classes.hotelImage}/>
        </div>
      </Container>
    </section>
  );
}

Gift.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Gift);
