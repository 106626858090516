import React from 'react';
import classNames from 'classnames'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

import Bank from '../../images/jpg/Bank.jpg'
import FirstRealRaclette from '../../images/jpg/FirsRealRaclette.jpg'
import Gonegratbahn from '../../images/jpg/Gonegratbahn.jpg'
import Hoellgrotte from '../../images/jpg/Hoellgrotte.jpg'
import Ikea from '../../images/jpg/Ikea.jpg'
import ImZug from '../../images/jpg/ImZug.jpg'
import Jungfraujoch1 from '../../images/jpg/Jungfraujoch.jpg'
import Jungfraujoch2 from '../../images/jpg/Jungfraujoch2.jpg'
import Lagerfeuer from '../../images/jpg/Lagerfeuer.jpg'
import Lausanne from '../../images/jpg/Lausanne.jpg'
import LausanneOlympicPark from '../../images/jpg/LausanneOlympicPark.jpg'
import LausannePier from '../../images/jpg/LausannePier.jpg'
import LausanneZug from '../../images/jpg/LausanneZug.jpg'
import Lengnau from '../../images/jpg/Lengnau.jpg'
import LengnauSpaziergang from '../../images/jpg/LengnauSpaziergang.jpg'
import LenzerHeideRodeln from '../../images/jpg/LenzerHeideRodeln.jpg'
import LockdownEssen from '../../images/jpg/LockdownEssen.jpg'
import LockdownEssen2 from '../../images/jpg/LockdownEssen2.jpg'
import LockdownFernsehen from '../../images/jpg/LockdownFernsehen.jpg'
import Luzern from '../../images/jpg/Luzern.jpg'
import Neuchatel from '../../images/jpg/Neuchatel.jpg'
import Rigi from '../../images/jpg/Rigi.jpg'
import SchlechtesWetter from '../../images/jpg/schlechteswetter.jpg'
import Schokoladenmuseum from '../../images/jpg/Schokoladenmuseum.jpg'
import Schwarzensee from '../../images/jpg/Schwarzensee.jpg'
import SchwarzenseeBus from '../../images/jpg/SchwarzenseeBus.jpg'
import SushiKochen from '../../images/jpg/SushiKochen.jpg'
import Uetliberg from '../../images/jpg/Uetliberg.jpg'
import UetlibergWanderung from '../../images/jpg/UetlibergWanderung.jpg'
import Unterendingen from '../../images/jpg/Unterendingen.jpg'
import VeloTour from '../../images/jpg/VeloTour.jpg'

import Wengen from '../../images/jpg/Wengen.jpg'
import Zermatt from '../../images/jpg/Zermatt.jpg'
import ZermattAbendessen from '../../images/jpg/ZermattAbendessen.jpg'
import ZermattBar from '../../images/jpg/ZermattBar.jpg'
import ZueriPanoramaweg from '../../images/jpg/ZueriPanoramaweg.jpg'
import Jungfraujoch_Berg from '../../images/jpg/Jungraujoch_Berg.jpg'
import Zermatt_Schwarzsee from '../../images/jpg/Zermatt_Schwarzsee.jpg'
import SchwarzseeWanderung from '../../images/jpg/SchwarzseeWanderung.jpg'



const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  images: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexWrap: 'wrap',
  },
  imageWrapper: {
    position: 'relative',
    display: 'block',
    padding: 0,
    borderRadius: 0,
    height: '60vh',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      height: '80vh',
    },
    '&:hover': {
      zIndex: 1,
    },
    '&:hover $imageBackdrop': {
      opacity: 0.15,
    },
    '&:hover $imageMarked': {
      opacity: 0,
    },
    '&:hover $imageTitle': {
      border: '4px solid currentColor',
    },
  },
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {
    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px 14px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    background: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
  sharedMomentsMessage: {
    marginTop: '15px'
  }
});

function SharedMoments(props) {
  const { classes } = props;

  const images = [
    {
      url: ZueriPanoramaweg,
      title: 'Sonniger Spaziergang - Panoramaweg Züri',
      width: '65%',
    },
    {
      url: LenzerHeideRodeln,
      title: 'Rodeln - Lenzer Heide',
      width: '35%',
    },
    {
      url: LockdownEssen2,
      title: 'Älplermagronen <3',
      width: '33%',
    },
    {
      url: LockdownEssen,
      title: 'Tortilla Wraps mit Zwiebel',
      width: '67%',
    },
    {
      url: FirstRealRaclette,
      title: 'Raclette <3',
      width: '100%',
    },
    {
      url: LockdownFernsehen,
      title: 'Lockdown',
      width: '40%',
    },
    {
      url: Schwarzensee,
      title: 'Schwarzsee Wanderung',
      width: '60%',
    },
    {
      url: SchwarzseeWanderung,
      title: 'Fabienne in ganzer "besonderer" Yoga Pose ',
      width: '55%',
    },
    {
      url: SchwarzenseeBus,
      title: 'Martin müde',
      width: '45%',
    },
    {
      url: Lausanne,
      title: 'On top of Lausanne',
      width: '50%',
    },
    {
      url: Bank,
      title: 'Beim Waschen eingegangen',
      width: '50%',
    },
    {
      url: LausanneOlympicPark,
      title: 'Olympic Park - Lausanne',
      width: '40%',
    },
    {
      url: LausannePier,
      title: 'Pier - Lausanne',
      width: '60%',
    },
    {
      url: LausanneZug,
      title: 'Zurück nach Fribourg',
      width: '65%',
    },
    {
      url: Hoellgrotte,
      title: 'Höllgrotte',
      width: '35%',
    },
    {
      url: Lagerfeuer,
      title: 'Mittagessen am Lagerfeuer',
      width: '50%',
    },
    {
      url: ImZug,
      title: 'Im Zug mit dir <3',
      width: '50%',
    },
    {
      url: Zermatt,
      title: 'Zermatt',
      width: '40%',
    },
    {
      url: Gonegratbahn,
      title: 'Gornergrat',
      width: '60%',
    },
    {
      url: Zermatt_Schwarzsee,
      title: 'Zermatt - Schwarzsee',
      width: '50%',
    },
    {
      url: ZermattAbendessen,
      title: 'Fondue',
      width: '50%',
    },
    {
      url: ZermattBar,
      title: 'Hanabi with Friends',
      width: '70%',
    },
    {
      url: SchlechtesWetter,
      title: 'Spass auch bei Regen',
      width: '30%',
    },
    {
      url: Wengen,
      title: 'Wengen',
      width: '40%',
    },
    {
      url: Jungfraujoch1,
      title: 'Fabienne in guter Gesellschaft',
      width: '60%',
    },
    {
      url: Jungfraujoch_Berg,
      title: 'Jungfraujoch',
      width: '65%',
    },
    {
      url: Jungfraujoch2,
      title: 'Top of Europe',
      width: '35%',
    },
    {
      url: UetlibergWanderung,
      title: 'Uetliberg Wanderung mit Luke und Tanja',
      width: '40%',
    },
    {
      url: Uetliberg,
      title: 'Uetliberg <3',
      width: '60%',
    },
    {
      url: Ikea,
      title: 'Neue Wohnung einrichten',
      width: '60%',
    },
    {
      url: Lengnau,
      title: 'Fusswärmer Modell "Martin"',
      width: '40%',
    },
    {
      url: Neuchatel,
      title: 'Neuchatel',
      width: '40%',
    },
    {
      url: LengnauSpaziergang,
      title: 'Spaziergang',
      width: '60%',
    },
    {
      url: Rigi,
      title: 'Rigi',
      width: '65%',
    },
    {
      url: Luzern,
      title: 'Luzern',
      width: '35%',
    },
    {
      url: Schokoladenmuseum,
      title: 'Schokoladenmuseum',
      width: '50%',
    },
    {
      url: VeloTour,
      title: 'Velo Tour',
      width: '50%',
    },
    {
      url: SushiKochen,
      title: 'Sushi selber gemacht',
      width: '35%',
    },
    {
      url: Unterendingen,
      title: 'Unterendingen',
      width: '65%',
    },
  ];

  return (
    <Container className={classes.root} component="section">
      <Typography variant="h4" marked="center" align="center" component="h2">
        Unser gemeinsames Jahr 2020
      </Typography>

      <Typography color="inherit" align="center" variant="h5" className={classNames(classes.h5, classes.sharedMomentsMessage)}>
        Kaum zu glauben, dass sich das Jahr schon wieder fast dem Ende zuneigt. Mir ist beim Durchschauen der Fotos wieder einmal
        mehr aufgefallen, wie viele unglaublich schöne Momente wir in diesem Jahr gemeinsam verbracht haben.
        Ich bin wirklich froh, einen so besonderen Menschen wie dich gefunden zu haben und gebe dich bestimmt nicht so schnell wieder her :).
        Ich habe ein paar Highlights aus meinen Fotos herausgepickt und als Foto Album zusammengestellt.
        Die Fotos sind nur leider nur ein Teil von dem, was wir in 2020 alles gemacht haben, aber das sind leider alle Fotos, die ich habe.
        Ich vermisse dich schon jetzt und freue mich, dich bald wiederzusehen {'<'}3
      </Typography>

      <div className={classes.images}>
        {images.map((image) => (
          <ButtonBase
            key={image.title}
            className={classes.imageWrapper}
            style={{
              width: image.width,
            }}
          >
            <div
              className={classes.imageSrc}
              style={{
                backgroundImage: `url(${image.url})`,
              }}
            />
            <div className={classes.imageBackdrop} />
            <div className={classes.imageButton}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                className={classes.imageTitle}
              >
                {image.title}
                <div className={classes.imageMarked} />
              </Typography>
            </div>
          </ButtonBase>
        ))}
      </div>
    </Container>
  );
}

SharedMoments.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SharedMoments);
