import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '../components/Typography';
import ProductHeroLayout from './ChristmasHeroLayout';

import RigiPanorama from '../../images/jpg/RigiPanorama.jpg'

const styles = (theme) => ({
  background: {
    backgroundImage: `url(${RigiPanorama})`,
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
  },
  button: {
    minWidth: 200,
  },
  h5: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  more: {
    marginTop: theme.spacing(2),
  },
});

function ChristmasHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <Typography color="inherit" align="center" variant="h2" marked="center">
        Frohe Weihnachten
      </Typography>
      <Typography color="inherit" align="center" variant="h5" className={classes.h5}>
          Herzlichen Glückwünsch Fabienne! Es sieht so aus, als hättest du meine schwierige Aufgabe gelöst und das Puzzle
          vollständig zusammengebaut. Wie du vielleicht schon erkannt hast, habe ich für dich deine ganz eigene Webseite kreiert :)
      </Typography>
    </ProductHeroLayout>
  );
}

ChristmasHero.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ChristmasHero);
